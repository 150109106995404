<template>
    <div @click="onClick" :class="['button_base',style,bgColor,fontSize]">
        {{this.txt}}
    </div>
</template>

<script>
    export default {
        name: "Button",
        props:{
            size:String,
            txt:String,
            bgColor:String,
            font:String
        },
        data(){
            return {
                style:"size",
                fontSize:'fontSize'
            }
        },
        created() {
            if(this.size){
                this.style=this.size
            }
            if(this.font){
                this.fontSize=this.font
            }
        },
        methods:{
            onClick(){
                this.$emit('onClick',null);
            }
        }

    }
</script>

<style scoped>

    .button_base{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        letter-spacing: 2px;
        border-radius: 8px;
    }

    .size{
        height: 80px;
        width: 200px;
    }

    .fontSize{
        font-size: 28px;
        color: white;
    }
</style>
