<template>
    <div class="index">
        <div class="top">
            <Banner :images="images" />
        </div>

        <div class="menu">
            <div class="menucontainer"
                 v-infinite-scroll="loadMore"
                 infinite-scroll-disabled="loading"
                 infinite-scroll-distance="10">

                <Button v-on:onClick="onClick" txt="alert 提示" bgColor="button_bgColor" />
                <Button v-on:onClick="onToastClick" txt="toast 提示" bgColor="button_bgColor" />
                <Button v-on:onClick="onSuccessClick" size="button_size" txt="toast 操作成功" bgColor="button_bgColor" />
                <Button v-on:onClick="onMessageboxClick" size="button_size" txt="消息确认框" bgColor="button_bgColor" />
                <Button v-on:onClick="onDateTimeClick" txt="选择日历" bgColor="button_bgColor" />
                <DatetimePicker
                        ref="picker"
                        type="datetime"
                        v-model="pickerValue"
                        year-format="{value}年"
                        month-format="{value}月"
                        date-format="{value}日"
                        hourFormat="{value}时"
                        minuteFormat="{value}分"
                        @confirm="handleConfirm"
                >
                </DatetimePicker>

                <div class="item">sdf</div>

                <div class="item">sdf</div>
                <div class="item">sdf</div>
                <div class="item">sdf</div>
                <div class="item">sdf</div>
                <div class="item">sdf</div>
                <div class="item">sdf</div>
                <div class="item">sdf</div>
                <div class="item">sdf</div>
                <div class="item">sdf</div>
                <div class="item">sdf</div>
                <div class="item">sdf</div>
                <div class="item">abc</div>
                <div v-for="item in data" :key="item.index">{{item.name}}</div>
                <div v-show="loading"  style="text-align: center;font-size: 13px;width: 100%;height:26px;">加载中...</div>
            </div>
        </div>
    </div>
</template>

<script>
    document.title="篱笆匠人"
    import Banner from "../xyg/banner/Banner";
    import Button from "../xyg/button/Button";
    import { Toast } from 'mint-ui';
    import { MessageBox } from 'mint-ui';
    import { DatetimePicker } from 'mint-ui';

    export default {
        name: "Index",
        components:{
            Banner,
            Button,
            DatetimePicker,
        },
        data(){
            return {
                pickerValue:false,
                loading:false,
                count: 0,
                data: [],
                images:[
                    {
                        image:'https://www.webhek.com/wordpress/wp-content/uploads/2014/03/syntax-1.png',
                        toUrl:''
                    },
                    {
                        image:'https://www.baidu.com/img/PCfb_5bf082d29588c07f842ccde3f97243ea.png',
                        toUrl:''
                    },
                    {
                        image:'https://t7.baidu.com/it/u=1956604245,3662848045&fm=193&f=GIF',
                        toUrl:''
                    }
                ]
            }
        },
        methods:{
            onClick(){
                alert('Info');
            },
            onToastClick(){
                Toast({
                    message: '提示',
                    position: 'bottom',
                    duration: 50000
                });
            },
            onSuccessClick(){
                Toast({
                    message: '操作成功',
                    iconClass: 'mintui mintui-success',
                    duration: 50000
                });
            },
            onMessageboxClick(){
                MessageBox({
                    message:'确定这么操作吗？',
                    title:'提示',
                    showCancelButton: true,
                    confirmButtonText:"确认/继续",
                    cancelButtonText:"取消/暂停"
                })
                    .then(action=>{
                        if(action==='confirm'){
                            console.log('确认');
                        }
                        else{
                            console.log('取消');
                        }
                    }).catch(error=>{
                        console.log('error',error);
                });
            },
            onDateTimeClick(){
                this.$refs.picker.open();
            },
            handleConfirm(value){
                console.log(value);
            },
            loadMore() {
                this.loading = true;
                setTimeout(() => {
                    for (var i = 0, j = 10; i < j; i++) {
                        this.data.push({name: this.count++ })
                    }
                    console.log(this.data)
                    this.loading = false;
                }, 2500);
            }
        }
    }
</script>

<style scoped>
    .index{
        position: absolute;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 750px;
    }
    .top{
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        height: 280px;
        width: 710px;
        display: flex;
    }

    .button_bgColor{
        background-color: red;
    }

    .button_size{
        width: 240px;
        height: 80px;
    }

    .menu{
        overflow: scroll;
        width: 750px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
    }

    .menu .menucontainer{
        margin-left: 20px;
        margin-right: 20px;
        width: 710px;
        margin-top: 20px;
    }

    .menu::-webkit-scrollbar{
        display:none;/*隐藏滚动条*/
    }

    .menu .item{
        height: 100px;
    }
</style>
